body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.react-h5-audio-player .toggle-play-wrapper .toggle-play-button {
    background-color: #28003D !important;
}

.react-h5-audio-player .toggle-play-wrapper .toggle-play-button i {
    box-shadow: #28003D 7px 0px 0px 0px inset !important;
}